.videoTag{
  position:relative;
  width: 100%;
  /* left:50%;
  top:50%; */
  height: 100%;
  object-fit: cover;
  z-index: -1;
  /* transform: translate(-50%, -50%); */

}



